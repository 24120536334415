// App.js
import React, { useState, useEffect, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import Preloader from "./components/PreLoader";

const Navbar = React.lazy(() => import("./layout/Navbar"));
const Footer = React.lazy(() => import("./layout/Footer"));
const AppRoutes = React.lazy(() => import("./routes/AppRoutes"));

function App() {
  const { i18n } = useTranslation();
  const language = i18n.language;

  // State to handle preloader visibility
  const [loading, setLoading] = useState(true);
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  // Simulate loading of assets, fonts, and images
  useEffect(() => {
    const loadAssets = async () => {
      try {
        // Simulate loading time for fonts or essential assets
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setAssetsLoaded(true);
      } catch (err) {
        console.error("Error loading assets:", err);
      }
    };
    loadAssets();
  }, []);

  // Simulate fetching initial data (if any)
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Simulate data fetching (e.g., from an API)
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setDataLoaded(true);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    fetchData();
  }, []);

  // Effect to track loading state
  useEffect(() => {
    if (assetsLoaded && dataLoaded) {
      setLoading(false);
    }
  }, [assetsLoaded, dataLoaded]);

  if (loading) {
    // Show preloader while loading is true
    return <Preloader />;
  }

  return (
    <div
      className={`w-full overflow-hidden font-northuraRegular  ${
        language === "en" ? "font-light" : "font-bold"
      }`}
      dir={`${language === "en" ? "ltr" : "rtl"}`}
    >
      <Navbar />
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;
