// components/Preloader.js
import Spline from "@splinetool/react-spline";
import React from "react";
import Loading from "../assets/images/logo loading.gif.gif";
const Preloader = () => {
  return (
    <div className="w-full h-screen bg-black">
      <div className="flex justify-center items-center h-full flex-col text-white">
        <img src={Loading} alt="loading" className=" object-cover" />
      </div>
    </div>
  );
};

export default Preloader;
